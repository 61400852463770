


<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
import { Modal } from "bootstrap";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      map_modal: "",
      notfication_settings: [],
      orders: [],
      user_details: [],
      categories: [],
      rate: [],
      coupons: [],
      branches: [],
      page: 1,
      vendor_id: 0,
      limit: 20,
      tot_pages: 0,
      state: state
    };
  },
  methods: {
    addCateogries() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addCateogries",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "name"
          },
          {
            model: "name_ar",
            type: "text",
            label: "name_ar"
          },
          {
            model: "hide_from_vendor",
            type: "text",
            label: "hide_from_vendor"
          },
          {
            model: "image",
            type: "file",
            label: "image"
          }
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: obj => {
              obj.vendor_id = this.vendor_id;
              this.http.do("cateogries", obj).then(() => {
                this.getVendorCateogries(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    editCateogries(app) {
      var data = {
        title: "popups.cateogries",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "name",
            value: app.name
          },
          {
            model: "name_ar",
            type: "text",
            label: "name",
            value: app.name
          },

          {
            model: "image",
            type: "file",
            label: "image",
            value: app.image
          }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: obj => {
              this.http.put("cateogries", app.id, obj).then(() => {
                this.getVendorCateogries(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    updateCateogries(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "name"
          },
          {
            model: "vendor_id",
            type: "text",
            label: "vendor_id"
          },
          {
            model: "hide_from_vendor",
            type: "text",
            label: "hide_from_vendor"
          },
          {
            model: "image",
            type: "text",
            label: "image"
          },
          {
            model: "products",
            type: "select",
            options: (() => {
              return this.products.map(data => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.products"
          }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: obj => {
              obj.emp_id = app.id;
              this.http.do("cateogries", obj).then(() => {
                this.getVendorCateogries(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    search() {
      this.CateogriesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("cateogries/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: ""
        })
        .then(res => {
          this.Cateogries = res.data;
          console.log("######## files", this.Cateogries);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.CateogriesSearchMode = false;
      this.get(this.page);
    },

    getCateogries() {
      this.http.get("cateogries").then(res => {
        this.Cateogries = res.data;
      });
    },
    deleteCateogries(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage"
      };
      this.popup.confirm(data).then(resp => {
        if (resp) {
          this.http.delete("cateogries", app.id).then(() => {
            this.getVendorCateogries(this.page);
          });
        }
      });
    },

    getVendorCateogries(page) {
      console.log(page);
      this.http
        .post("cateogries/none-vendor/paginate", {
          limit: this.limit,
          page: page,
          vendor_id: this.$route.params.id
        })
        .then(res => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.categories = res.data;
          res.data.forEach(item => {
            this.products.push(item.products);
          });
        });
    },
    changeStates(app) {
      var status = 0;
      if (app.status == 1) {
        status = 0;
      }
      if (app.status == 0) {
        status = 1;
      }
      let data = { id: this.vendor_id, status: status };
      this.http.post("users/update-status", data).then(res => {
        if (res) {
          this.get(1);
        }
      });
    },

    get(page) {
      console.log(page);
      // let id = this.id
      this.http
        .post("orders/none-partners/", {
          limit: this.limit,
          page: page,
          vendor_id: this.vendor_id
        })
        .then(res => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.orders = res.data;
        });
    },
    openMapModal(data) {
      console.log("hi");
      console.log(data);
      this.map_modal.show();
    }
  },
  mounted() {
    this.map_modal = new Modal("#map_modal");
  },
  created() {
    // this.map_modal= Modal('#map_modal')
    this.vendor_id = this.$route.params.id;
    this.get(1);
    this.getVendorCateogries(1);
  }
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.details.text')"
    />
    <div class="row">
      <div class="col-8">
        <b-tabs
          content-class="p-4"
          class="pt-2"
          nav-wrapper-class="nav-item"
          nav-class="justify-content-start nav-tabs-custom"
        >
          <b-tab :title="$t('menu.menuitems.categories.text')" active>
            <div class="row">
              <div class="col-6">
                <div class="search-box chat-search-box w-50">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      type="text"
                      class="form-control fa-lg text-light"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      :placeholder="$t('popups.search')"
                      v-model="searchModel"
                      style="background-color: #2a3042 !important"
                    />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                  </div>
                </div>
              </div>
              <div class="col-6" style="display: flex; justify-content: flex-end">
                <button
                  class="btn-close text-light"
                  v-if="CateogriesSearchMode"
                  @click="cancelappsearchMode()"
                ></button>
                <button type="button" @click="addCateogries()" class="btn btn-light float-end mb-4">
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
              <thead>
                <tr class="text-light text-center" style="background-color: #2a3042 !important">
                  <th scope="col">{{ $t('cateogries.id') }}</th>
                  <th scope="col">{{ $t('cateogries.name') }}</th>
                  <th scope="col">{{ $t('cateogries.image') }}</th>
                  <th scope="col">{{ $t('cateogries.created') }}</th>
                  <th scope="col">{{ $t('cateogries.updated') }}</th>
                  <th scope="col">{{ $t('cateogries.actions') }}</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, i) in categories" :key="app" class="text-center">
                  <td>{{ i + 1 }}</td>
                  <td>{{ app?.name }}</td>
                  <td>
                    <img
                      :src="$store.state?.auth?.mediaUrl + app?.image?.split('public')[1]"
                      style="width: 64px;height: 64px;"
                      alt
                    />
                  </td>
                  <td>{{ app?.created?.split('To')[0] }}</td>
                  <td>{{ app?.updated?.split('T')[0] }}</td>
                  <td class="d-flex">
                    <router-link
                      class="btn btn-success mx-1"
                      :to="'/product/' + app.id"
                    >{{ $t("menu.menuitems.products.text") }}</router-link>
                    <a
                      class="btn btn-primary mx-1"
                      @click="editCateogries(app)"
                      href="javascript: void(0);"
                      role="button"
                    >
                      {{
                      $t("popups.edit") }}
                    </a>
                    <a
                      class="btn btn-danger"
                      href="javascript: void(0);"
                      role="button"
                      @click="deleteCateogries(app)"
                    >
                      {{
                      $t("popups.delete") }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-tab>
          <!-- marketing channels -->
          <!-- marketing channels -->
          <b-tab :title="$t('menu.menuitems.orders.text')">
            <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
              <thead>
                <tr class="text-light text-center" style="background-color: #2a3042 !important">
                  <th scope="col">{{ $t('orders.id') }}</th>
                  <th scope="col">{{ $t('orders.custmor_id') }}</th>
                  <th scope="col">{{ $t('orders.location') }}</th>
                  <th scope="col">{{ $t('orders.descr') }}</th>
                  <th scope="col">{{ $t('orders.status') }}</th>
                  <th scope="col">{{ $t('orders.created') }}</th>
                  <th scope="col">{{ $t('orders.updated') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app,index) in orders" :key="app" class="text-center">
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.id"># {{ index+1 }}</router-link>
                  </td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.id">{{ app?.order?.user?.name }}</router-link>
                  </td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.id">{{ app?.order?.phone }}</router-link>
                  </td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.id">
                      {{
                      app?.order_description
                      }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.id">{{ app?.status?.status_ar }}</router-link>
                  </td>
                  <td>{{ app?.order?.created?.split('T')[0] }}</td>
                  <td>{{ app?.order?.updated?.split('T')[0] }}</td>
                </tr>
              </tbody>
            </table>
          </b-tab>
        </b-tabs>
      </div>
      <div class="col">
        <div class="row mt-2">
          <div class="d-print-none">
            <div class="float-end">
              <a
                href="javascript:window.print()"
                class="btn btn-success mx-1 waves-effect waves-light me-1"
              >
                <i class="fa fa-print"></i>
              </a>
              <a
                v-if="user_details?.status"
                href="#"
                @click="changeStates(user_details)"
                class="btn btn-primary w-md waves-effect waves-light"
              >
                {{
                $t("popups.active") }}
              </a>
              <a
                v-if="!user_details?.status"
                href="#"
                @click="changeStates(user_details)"
                class="btn btn-warning w-md mx-1 waves-effect waves-light"
              >
                {{
                $t("popups.enactive") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="map_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog" style="max-width: 1024px">
        <div class="modal-content">
          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" id="exampleModalLabel">{{ $t("popups.order_tracking") }}</h5>
            <button
              type="button"
              class="btn-close-add"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            >X</button>
          </div>

          <div class="modal-body px-0">
            <div class="row">
              <div class="col">
                <img src="../../../../public/image/map.jpeg" class="img-fluid" alt srcset />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




